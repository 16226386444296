<template>
  <a-modal
    title="选择供应商"
    width="50%"
    :visible="visible"
    :destroyOnClose="true"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <div class="container">
      <a-row>
        <a-col :span="24" class="right">
          <div class="right-content">
            <!-- 筛选 -->
            <div class="table-page-search-wrapper">
              <a-form layout="inline">
                <a-row :gutter="40">
                  <!-- 代理商姓名 -->
                  <a-col :md="8" :sm="24">
                    <a-form-item label="名称/账号">
                      <a-input v-model="queryParam.keyword" allow-clear placeholder="请输入" />
                    </a-form-item>
                  </a-col>

                  <!-- 按钮 -->
                  <a-col :md="16" :sm="24">
                    <a-button style="margin-left: 8px" @click="() => ((queryParam = {}), initTableData())"
                      >重置</a-button
                    >
                    <a-button type="primary" style="margin-left: 8px" @click="initTableData">搜索</a-button>
                  </a-col>
                </a-row>
              </a-form>
            </div>
            <!-- 表格 -->
            <a-table
              :columns="columns"
              :data-source="data"
              :loading="loading"
              :pagination="false"
              rowKey="id"
              :row-selection="rowSelection"
            >
              <!-- 视频列表状态插槽 -->
              <span slot="delFlag" slot-scope="text, record">
                <a-tag :color="text == 1 ? 'green' : text == 3 ? 'red' : ''">
                  {{ text == 1 ? '正常' : text == 3 ? '禁用' : '' }}
                </a-tag>
              </span>
            </a-table>
            <!-- 分页组件 -->
            <div style="margin-top: 20px; display: flex; flex-direction: row-reverse">
              <a-pagination
                :pageSize="page.pageSize"
                :current="page.pageNo"
                show-size-changer
                :page-size-options="['10', '20', '50', '100']"
                show-quick-jumper
                :total="total"
                :show-total="(total) => `共 ${total} 条`"
                @change="pageChange"
                @showSizeChange="sizeChange"
              ></a-pagination>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
  </a-modal>
</template>

<script>
import { customerPageList } from '@/api/modular/mallLiving/userAdmin'
import { storePageList } from '@/api/modular/mallLiving/enterprises'

const columns = [
  {
    title: '序号',
    width: '100px',
    align: 'center',
    customRender: (text, record, index) => `${index + 1}`,
  },
  {
    title: '供应商名称',
    width: '100px',
    dataIndex: 'nickname',
    align: 'center',
  },
  {
    title: '状态',
    dataIndex: 'delFlag',
    width: '100px',
    key: 'delFlag',
    align: 'center',
    scopedSlots: { customRender: 'delFlag' },
  },
  {
    title: '账号',
    align: 'center',
    width: '150px',
    dataIndex: 'mobile',
  },
  {
    title: '联系人',
    width: '130px',
    dataIndex: 'linkName',
    align: 'center',
  },
  {
    title: '联系方式',
    width: '150px',
    dataIndex: 'linkMobile',
    align: 'center',
  },
  {
    title: '收货地址',
    width: '150px',
    dataIndex: 'address',
    align: 'center',
  },

  {
    title: '创建时间',
    dataIndex: 'createTime',
    width: '150px',
    key: 'createTime',
    align: 'center',
  },
]
export default {
  name: 'chooseUser',

  data() {
    return {
      columns,
      visible: false, //modal框显示状态
      data: [],
      queryParam: {},
      page: {
        pageNo: 2,
        pageSize: 10,
      },
      total: 0,
      loading: false,
      selectedRowKeys: [],
      selectedRows: [],
    }
  },
  computed: {
    rowSelection() {
      const { selectedRowKeys } = this
      return {
        type: 'radio',
        selectedRowKeys, // 一定要加上这一行代码，清除才会有作用
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys
          this.selectedRows = selectedRows
        },
      }
    },
  },
  methods: {
    init() {
      this.visible = true
      this.initTableData()
    },
    handleSubmit() {
      if (!this.selectedRows.length) {
        return this.$message.info('请选择供应商！')
      }
      const { nickname: name, id } = this.selectedRows[0]
      this.$parent.setUser({ name, id })
      this.handleCancel()
    },
    // 点击遮罩层或右上角叉或取消按钮的操作
    handleCancel() {
      this.visible = false
    },
    pageChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    sizeChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    initTableData() {
      this.page.pageNo = 1
      this.getList()
    },
    getList() {
      this.loading = true
      let query = {
        queryParam: this.queryParam,
        page: this.page,
      }
      this.selectedRows = []
      this.selectedRowKeys = []
      storePageList(query)
        .then((res) => {
          if (res.success) {
            this.data = res.data.rows
            this.data.forEach((item) => {
              item.checked = item.delFlag == 1
            })
            this.total = res.data.totalRows
            this.loading = false
          }
        })
        .finally((res) => {
          setTimeout(() => {
            this.loading = false
          }, 5000)
        })
    },
  },
}
</script>

<style scoped>
.right {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 100);
}

.right-content {
  box-sizing: border-box;
  padding: 20px;
  overflow: auto;
}

.icon {
  font-size: 20px;
  margin: 0 5px;
}

img {
  width: 40px;
  height: 40px;
}
</style>
